import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Investors from './pages/Investor';
import Licensing from './pages/Licensing';
import Interactions from './pages/Interactions';
import Team from './pages/Team';
import Careers from './pages/Careers';
import { useEffect } from 'react';
import { initGA, logPageView } from './utils/analytics';

const AppContainer = styled.div`
  min-height: 100vh;
  background-color: #000000;
  color: white;
  font-family: 'Geometry Soft Pro Regular C', sans-serif;
`;

function App() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/licensing" element={<Licensing />} />
          <Route path="/interactions" element={<Interactions />} />
          <Route path="/team" element={<Team />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App; 