import styled from 'styled-components';
import { FaPlay, FaDownload } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

const PageContainer = styled.div`
  padding: 4rem 10%;
  color: white;
`;

const Header = styled(motion.div)`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: #E0E0E0;
`;

const CharactersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const CharacterCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(255, 215, 0, 0.1), transparent);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const CharacterName = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const CharacterAka = styled.span`
  font-size: 0.9rem;
  color: #FFD700;
`;

const CharacterDescription = styled.p`
  color: #E0E0E0;
  margin: 1rem 0;
`;

const VideoButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: 1px solid #FFD700;
  color: #FFD700;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #FFD700;
    color: black;
    transform: scale(1.05);
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #FF69B4;
  border: none;
  color: white;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0 auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const VideoOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 90vw;
  height: 50.625vw;
  max-width: 1200px;
  max-height: 675px;
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: -50px;
  right: 0;
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
  padding: 10px;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  background: black;
  border-radius: 15px;
`;

const characters = [
  {
    name: "Abraham Lincoln",
    aka: "BigLinc",
    description: "No introduction needed. He's got some quests for you.",
    videoId: "F7sr6saktg0"
  },
  {
    name: "Skibidi toilet",
    description: "we've given him life.",
    videoUrl: "https://www.tiktok.com/@glimpsewiki/video/7428954864807349546"
  },
  {
    name: "The Pope",
    description: "Witty and smart. He's one of our first characters.",
    videoId: "pKcH9R-WsTE"
  },
  {
    name: "Princess Leia",
    description: "She's only got one mission..",
    videoId: "MX_L6-E0EiM"
  },
  {
    name: "CatWoman",
    description: "Is she in love with batman or not?",
    videoId: "-lNdVgUHV4w"
  },
  {
    name: "Maggie",
    description: "Obsessed with tech, abit fiesty (real life influencer). Be careful.",
    videoId: "R3bfKRlkOGg"
  }
];

function Interactions() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    hover: {
      scale: 1.03,
      transition: {
        duration: 0.3
      }
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.3,
        yoyo: Infinity
      }
    }
  };

  const handleVideoClick = (videoId, videoUrl) => {
    if (videoUrl) {
      window.open(videoUrl, '_blank');
    } else {
      setSelectedVideo(videoId);
    }
  };

  return (
    <PageContainer>
      <Header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Title>Explore Character Interactions</Title>
        <Subtitle>Immerse yourself in character storylines through augmented reality.</Subtitle>
      </Header>

      <AnimatePresence>
        {selectedVideo && (
          <VideoOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedVideo(null)}
          >
            <VideoContainer onClick={e => e.stopPropagation()}>
              <CloseButton 
                onClick={() => setSelectedVideo(null)}
                whileHover={{ scale: 1.1 }}
              >
                ×
              </CloseButton>
              <VideoWrapper>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${selectedVideo}?modestbranding=1&showinfo=0&controls=1&rel=0`}
                  title="Character Interaction"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </VideoWrapper>
            </VideoContainer>
          </VideoOverlay>
        )}
      </AnimatePresence>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <CharactersGrid>
          {characters.map((character, index) => (
            <CharacterCard
              key={index}
              variants={cardVariants}
              whileHover="hover"
              initial="hidden"
              animate="visible"
            >
              <CharacterName>
                {character.name}
                {character.aka && <CharacterAka> Aka: {character.aka}</CharacterAka>}
              </CharacterName>
              <CharacterDescription>{character.description}</CharacterDescription>
              <VideoButton
                onClick={() => handleVideoClick(character.videoId, character.videoUrl)}
                variants={buttonVariants}
                whileHover="hover"
              >
                <FaPlay /> Watch Interaction
              </VideoButton>
            </CharacterCard>
          ))}
        </CharactersGrid>
      </motion.div>
    </PageContainer>
  );
}

export default Interactions;
