import ReactGA from 'react-ga4';

// Initialize GA with your measurement ID
export const initGA = () => {
  ReactGA.initialize('G-Y0YDEYFQLB'); // Your actual measurement ID from the screenshot
};

// Track page views
export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

// Track button clicks and other events
export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// Track social media clicks
export const logSocialClick = (network) => {
  ReactGA.event({
    category: 'Social',
    action: 'Click',
    label: network
  });
};

// Track downloads
export const logDownload = () => {
  ReactGA.event({
    category: 'Download',
    action: 'Click',
    label: 'App Download'
  });
};

// Track email clicks
export const logEmailClick = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'Click',
    label: 'Email'
  });
}; 