import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/output-onlinepngtools.png';

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  height: 40px;
  margin-top: 15px;
`;

const Logo = styled.img`
  height: 70px;

  @media (max-width: 768px) {
    margin-left: -1rem;
  }
`;

// Desktop Menu
const DesktopMenu = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    display: none; // Hide on mobile
  }
`;

// Mobile Menu Container
const MobileMenu = styled.div`
  display: none; // Hide by default on desktop
  
  @media (max-width: 768px) {
    display: block; // Only show on mobile
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  color: #FFD700;
`;

const MobileMenuItems = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 20px;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #FFD700;
  gap: 1rem;
  z-index: 1000;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #FFD700;
  border-radius: 25px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 215, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
`;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Add this function to handle link clicks
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <Nav>
      <Link to="/">
        <Logo src={logo} alt="Glimpse Logo" />
      </Link>
      
      {/* Desktop Menu */}
      <DesktopMenu>
        <NavLink to="/licensing">Businesses & Licensing</NavLink>
        <NavLink to="/team">Leadership Team</NavLink>
        <NavLink to="/careers">Careers</NavLink>
      </DesktopMenu>

      {/* Mobile Menu */}
      <MobileMenu>
        <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </MenuIcon>
        <MobileMenuItems isOpen={isMenuOpen}>
          <NavLink to="/licensing" onClick={handleLinkClick}>Businesses & Licensing</NavLink>
          <NavLink to="/team" onClick={handleLinkClick}>Leadership Team</NavLink>
          <NavLink to="/careers" onClick={handleLinkClick}>Careers</NavLink>
        </MobileMenuItems>
      </MobileMenu>
    </Nav>
  );
}

export default Navbar;