import styled from 'styled-components';
import { FaStar, FaUsers, FaUserFriends, FaGlobeAmericas, FaYoutube, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { 
  logEvent, 
  logSocialClick, 
  logDownload, 
  logEmailClick 
} from '../utils/analytics';

const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  margin-top: 65px;
  
  @media (max-width: 768px) {
    height: auto;
    margin-top: 0;
    overflow-y: visible; // Allow content to extend beyond container
    position: static; // Remove any positioning constraints
  }
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  max-width: 1400px;
  width: 100%;
  margin-top: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding-right: 1rem;
  gap: 20%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
    margin-top: 0;
    position: static; // Remove any positioning constraints
    overflow: visible; // Allow content to flow naturally
  }
`;

const TextColumn = styled.div`
  flex: 1;
  padding: 1rem;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    margin-top: 2rem;
  }
`;

const VideoColumn = styled.div`
  flex: 1;
  padding: 1rem;

  @media (max-width: 768px) {
    order: 3; // This moves it after the stats
    width: 100%; // Ensure full width on mobile
    margin-top: 2rem; // Add space above the video
    padding: 0; // Remove padding on mobile if needed
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: calc(100vh - 100px);
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 300px; // Fixed height for mobile
    margin-top: 0rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 7.5rem 0 0.5rem 0;
  width: 100%;
  max-width: 800px;
  text-align: left;
  position: relative;
  left: -40px;
  @media (max-width: 768px) {
    font-size: 1.8rem;
    left: 0;
    margin: 3rem 0 1rem 0;
    padding: 0 1rem;
  }
`;

const Slogan = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  position: relative;
  left: -40px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    left: 0;
    padding: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  line-height: 1.4;
  text-align: left;
  position: relative;
  left: -20px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    left: 0;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
`;

const DownloadButton = styled.a`
  position: relative;
  left: -45px;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #FF69B4;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: white;
  display: inline-block;
  
  @media (max-width: 768px) {
    left: 0;
    padding: 0.8rem 1.6rem;
    font-size: 1.3rem;
    margin: 1rem;
  }
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
`;

const Stars = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 0.5rem;
`;

const ReviewText = styled.span`
  font-size: 0.9rem;
  color: #E0E0E0;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  left: -20px;
  gap: 3rem;
  margin: 2rem 0;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    padding: 0 1rem;
    gap: 1rem;
    margin: 2rem 0;
  }
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

const StatIcon = styled.div`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 0.5rem;
`;

const StatNumber = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StatLabel = styled.span`
  font-size: 1rem;
  text-align: center;
  max-width: 120px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const AnimatedWord = styled.span`
  color: #FFD700;
  transition: opacity 0.5s ease-in-out;
  font-size: 1em;
  border: 3px solid #FFD700;
  padding: 0.2em 0.5em;
  border-radius: 4px;

  @media (max-width: 768px) {
    margin-top: 1rem;
    display: inline-block;
  }
`;

const SocialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0;
  position: relative;
  left: -40px;

  @media (max-width: 768px) {
    left: 0;
    margin: 2rem 0;
  }
`;

const SocialText = styled.p`
  font-size: 1.2rem;
  color: #E0E0E0;
  margin-bottom: 1rem;
`;

const SocialButtonsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: center;
`;

const SocialButton = styled.a`
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFD700;
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ContactButton = styled.a`
  color: #FFD700;
  font-size: 1.1rem;
  text-decoration: none;
  margin-top: 1.5rem;
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border: 2px solid #FFD700;
  border-radius: 50px;
  transition: all 0.3s ease;
  

  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 215, 0, 0.1);
  }
`;

const AnimatedArrow = styled.div`
  position: absolute;
  right: -200px;
  top: 40%;
  transform: translateY(-50%);
  font-size: 15rem;
  color: #FFD700;
  animation: bounceRight 2s infinite;

  @keyframes bounceRight {
    0%, 100% {
      transform: translateX(0) translateY(-50%);
      opacity: 1;
    }
    50% {
      transform: translateX(10px) translateY(-50%);
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    display: none; // Hide arrow on mobile
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem auto;
  max-width: 800px;
  width: 100%;
  padding-left: 20%;  // This will push the content more to the left
`;

const FeatureItem = styled.div`
  font-size: 2rem;
  color: white;
  display: grid;
  grid-template-columns: 50px 1fr;  // Fixed width for emoji column
  align-items: center;
  gap: 1.5rem;
  
  .emoji {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;  // Slightly larger emojis
  }
  
  .text {
    text-align: left;
    line-height: 1.2;
  }
`;

function Home() {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ['Learning', 'Travel', 'Memes', 'Gaming', 'Social'];
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 1500); // Changes every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const handleDownloadClick = () => {
    logDownload();
    // Your existing download logic
  };

  const handleSocialClick = (network) => {
    logSocialClick(network);
  };

  return (
    <HomeContainer>
      <HeroSection>
        <ContentContainer>
          <TextColumn>
            <Title>GLIMPSE: AUGMENTED INTELLIGENT REALITY</Title>
            <Slogan>
              Use Glimpse for <AnimatedWord>{words[currentWord]}</AnimatedWord>
            </Slogan>
            <Description>
              🎮 Join Gamified quests.<br/>
              🤝 Challenge your friends.<br/>
              🌎 Learn about your local area. <br/>
              💰 Raising Pre-seed [email for details] <br/>
              🤝 Partners with Niantic.
            </Description>
            <DownloadButton 
              onClick={handleDownloadClick}
              href="https://apps.apple.com/mt/app/glimpse-valletta/id6736998656"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Now
            </DownloadButton>

            <StatsContainer>
              <StatItem>
                <StatIcon>
                  <FaUsers />
                </StatIcon>
                <StatNumber>15</StatNumber>
                <StatLabel>Characters</StatLabel>
              </StatItem>

              <StatItem>
                <StatIcon>
                  <FaUserFriends />
                </StatIcon>
                <StatNumber>4</StatNumber>
                <StatLabel>Team Members</StatLabel>
              </StatItem>

              <StatItem>
                <StatIcon>
                  <FaGlobeAmericas />
                </StatIcon>
                <StatNumber>25,000</StatNumber>
                <StatLabel>Global Mapped Locations</StatLabel>
              </StatItem>
            </StatsContainer>

            <SocialSection>
              <SocialText>Want to see more of Glimpse? Follow us on social media!</SocialText>
              <SocialButtonsContainer>
                <SocialButton 
                  href="https://www.youtube.com/@Glimpse-l8k" 
                  onClick={() => handleSocialClick('YouTube')}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <FaYoutube size={24} />
                </SocialButton>
                <SocialButton 
                  href="https://www.linkedin.com/company/glimpsewiki/" 
                  onClick={() => handleSocialClick('LinkedIn')}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={24} />
                </SocialButton>
                <SocialButton 
                  href="https://www.instagram.com/glimpse.stories/" 
                  onClick={() => handleSocialClick('Instagram')}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={24} />
                </SocialButton>
                <SocialButton 
                  href="https://www.tiktok.com/@glimpsewiki" 
                  onClick={() => handleSocialClick('TikTok')}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <FaTiktok size={24} />
                </SocialButton>
              </SocialButtonsContainer>
              
              <ContactButton 
                href="mailto:michael@glimpse.wiki"
                onClick={() => logEmailClick()}
              >
                michael@glimpse.wiki
              </ContactButton>
            </SocialSection>
            <AnimatedArrow>→</AnimatedArrow>
          </TextColumn>
          
          <VideoColumn>
            <VideoWrapper
              onClick={() => logEvent('Video', 'Play', 'Demo Video')}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/_JD3gw_hEt0"
                title="Augmented Intelligent reality is here."
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            </VideoWrapper>
          </VideoColumn>
        </ContentContainer>
      </HeroSection>
    </HomeContainer>
  );
}

export default Home; 